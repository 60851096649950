import * as React from 'react'

export default function SkipToContent() {
  return (
    <a href="#content" className="sr-only focus:not-sr-only focus:outline-none">
      <span className="text-center py-2 block bg-primary-500 text-white font-bold">
        Skip to content
      </span>
    </a>
  )
}
