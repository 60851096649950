import * as React from 'react'
import classNames from 'classnames'
import { ThemeLightIcon, ThemeDarkIcon, ThemeSystemIcon } from './Icons'
import { useRef } from 'react'

declare global {
  const prefersDark: (theme: Theme) => boolean
  const switchTheme: (theme: Theme) => void
  const storedTheme: (theme?: Theme) => Theme
}

enum Theme {
  Light = 'light',
  Dark = 'dark',
  System = 'system',
}

interface ThemeSwitcherProps {
  className?: string
}

export default function ThemeSwitcher({ className }: ThemeSwitcherProps) {
  //
  // Manipulate elements outside React
  // rather than using state hooks.
  //
  // There are issues between SSR and
  // client hydration as localStorage does
  // not exist on the server.
  //
  const ref = useRef<HTMLDivElement>(null)
  const sizeClasses = 'w-4 h-4'
  const icons = {
    [Theme.Light]: <ThemeLightIcon className="w-full h-full" />,
    [Theme.Dark]: (
      <ThemeDarkIcon className="-translate-y-[1px] translate-x-[1px] w-full h-full" />
    ),
    [Theme.System]: <ThemeSystemIcon className="w-full h-full" />,
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const theme = event.target.dataset.theme as Theme
    switchTheme(theme)
    storedTheme(theme)
    renderActive(theme)
  }

  function renderActive(theme: Theme) {
    const controls =
      ref.current?.querySelectorAll<HTMLDivElement>('div[data-control]')
    controls?.forEach(control => {
      if (ref.current === null) return
      const input = control.querySelector<HTMLInputElement>('input[data-theme]')
      if (input === null) return
      const isCurrent = input.dataset.theme === theme

      if (isCurrent) input.setAttribute('checked', 'true')

      const activeClasses = [
        '!text-primary-500',
        '!focus-within:text-white',
        '!focus-within:bg-primary-500',
      ]
      activeClasses.forEach(activeClass =>
        control.classList.toggle(activeClass, isCurrent),
      )
    })
  }

  React.useEffect(() => {
    renderActive(storedTheme())
  }, [])

  React.useEffect(() => {
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)')

    function osThemeListener() {
      switchTheme(matchMedia.matches ? Theme.Dark : Theme.Light)
    }

    matchMedia.addEventListener('change', osThemeListener)

    return () => matchMedia.removeEventListener('change', osThemeListener)
  }, [])

  return (
    <div
      ref={ref}
      className={classNames(
        className,
        'inline-flex justify-end items-center gap-1.5',
      )}
    >
      <legend className="sr-only">Theme</legend>
      {Object.entries(Theme).map(([label, value]) => {
        const id = `theme-${value}`
        return (
          <div
            data-control={true}
            key={value}
            className={classNames(
              'relative box-content p-1.5 flex items-center justify-center rounded-lg',
              sizeClasses,
              'text-mono-400',
            )}
          >
            {icons[value]}
            <label className="sr-only" htmlFor={id}>
              {label}
            </label>
            <input
              id={id}
              data-theme={value}
              name="site-theme"
              type="radio"
              onChange={handleChange}
              className={classNames(
                'absolute top-0 left-0 w-full h-full',
                'appearance-none cursor-pointer',
                'focus-visible:outline-none',
              )}
            />
          </div>
        )
      })}
    </div>
  )
}
