import './bootstrap'
import '../css/app.css'
import.meta.glob(['../images/**'])
import * as React from 'react'
import { hydrateRoot } from 'react-dom/client'
import { createInertiaApp } from '@inertiajs/react'
import Layout from './Pages/Layout'

createInertiaApp({
  progress: {
    includeCSS: false,
  },
  resolve: async name => {
    const pages = import.meta.glob('./Pages/**/*.tsx')
    const page = await pages[`./Pages/${name}.tsx`]()

    // @ts-ignore
    if (page.default.layout === undefined) {
      // @ts-ignore
      page.default.layout = (page: React.ReactNode) => (
        <Layout children={page} />
      )
    }

    return page
  },
  setup({ el, App, props }) {
    hydrateRoot(
      el,
      <React.StrictMode>
        <App {...props} />
      </React.StrictMode>,
    )
  },
})
