import * as React from 'react'
import { usePage } from '@inertiajs/react'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  InstantSearchSSRProvider,
  InstantSearchServerState,
} from 'react-instantsearch'
import GlobalProps = App.Data.Pages.GlobalProps
import ThemeSwitcher from '../Components/ThemeSwitcher'
import SkipToContent from '../Components/SkipToContent'

interface LayoutProps {
  children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
  const { url, props } = usePage<
    GlobalProps & { serverState?: InstantSearchServerState }
  >()
  const searchClient = React.useMemo(
    () => algoliasearch(props.algolia.appId, props.algolia.searchKey),
    [props.algolia.appId, props.algolia.searchKey],
  )

  return (
    <InstantSearchSSRProvider {...props.serverState}>
      <InstantSearch
        key={url}
        searchClient={searchClient}
        indexName={'addons'}
        initialUiState={props.searchState}
        future={{ preserveSharedStateOnUnmount: true }}
      >
        <SkipToContent />
        <div className="px-8 pb-20 max-w-7xl mx-auto">
          <div className="flex justify-end mt-0.5">
            <ThemeSwitcher />
          </div>
          {children}
        </div>
      </InstantSearch>
    </InstantSearchSSRProvider>
  )
}
